
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

// Safaris
import Safaris from './Components/Pages/Safaris/Safaris';
import SafariDetail from './Components/Pages/Safaris/SafariDetail';
import SafariBooking from './Components/Pages/Safaris/SafariBooking';

// packages
import TourPackages from './Components/Pages/Packages/TourPackages';
import PackageDetail from './Components/Pages/Packages/PackageDetail';
import PackageBooking from './Components/Pages/Packages/PackageBooking';

// Contact us
import ContactUs from './Components/Pages/ContactUs/ContactUs';

// About us
import AboutUs from './Components/Pages/AboutUs/AboutUs';

// Quotes
import Reservation from './Components/Pages/Reservation/Reservation';

function App() {

	return (
        <Router >
			<Routes>
				<Route path="/" element={<Safaris/>} />
				<Route path="/safariDetail" element={<SafariDetail/>} />
				<Route path="/safariBooking" element={<SafariBooking/>} />
				<Route path="/packages" element={<TourPackages/>} />
				<Route path="/packageDetail" element={<PackageDetail/>} />
				<Route path="/packageBooking" element={<PackageBooking/>} />
				<Route path="/contactUs" element={<ContactUs/>} />
				<Route path="/aboutUs" element={<AboutUs/>} />
				<Route path="/reservation" element={<Reservation/>} />


			</Routes>
        </Router>
		
);}

export default App;
