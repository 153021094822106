

import React,{Component} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIPackageNamesList, APIPackageReservationCreate, APISafariNamesList, APISafariReservationCreate, AUTH_TOKEN } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import TourIcon from "../../Utilities/imgs/tour.png"
import { getTodaysDate } from '../../Utilities/Functions';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Reservation extends Component {
constructor(props){
    
    super(props);
    this.state = {

        SafariNamesList:[],
        PackageNamesList:[],

        // Safari Reservation
        SafariReservationTitle:'',
        SafariCustomerName:'',
        SafariCustomerEmail:'',
        SafariCustomerPhone:'',
        SafariCustomerCountry:'',
        SafariCustomerNumberOfPeople:'',
        SafariCustomerSafariDays:'',
        CustomerAccommodationRange:'',
        SafariCustomerArrivalDate:'',
        SafariCustomerOtherNotes:'',

        // Package Reservation
        PackageReservationTitle:'',
        PackageCustomerName:'',
        PackageCustomerEmail:'',
        PackageCustomerPhone:'',
        PackageCustomerCountry:'',
        PackageCustomerNumberOfPeople:'',
        PackageCustomerAccommodationRange:'',
        PackageCustomerArrivalDate:'',
        PackageCustomerOtherNotes:'',

        // Posting
        SuccessPostMsg:'',
        ErrorPostMsg:'',
        ShowPostButton:true,
        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // Screens 
        ShowSafariReservationScreen:true,
        ShowPackageReservationScreen:false,
        // buttons
        ShowSafariReservationButton:true,
        ShowPackageReservationButton:true,

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

    }    
}

componentDidMount(){
    this.loadData()
}

loadData = () =>{
    axios.get(APISafariNamesList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SafariNamesList:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })

    axios.get(APIPackageNamesList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({PackageNamesList:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}


setSafariCustomerReservationTitleInput = (text) =>{this.setState({SafariReservationTitle:text.target.value});}
setSafariCustomerNameInput = (text) =>{this.setState({SafariCustomerName:text.target.value});}
setSafariCustomerEmailInput = (text) =>{this.setState({SafariCustomerEmail:text.target.value});}
setSafariCustomerPhoneInput = (text) =>{this.setState({SafariCustomerPhone:text.target.value});}
setSafariCustomerCountryInput = (text) =>{this.setState({SafariCustomerCountry:text.target.value});}
setSafariCustomerNumberOfPeopleInput = (text) =>{this.setState({SafariCustomerNumberOfPeople:text.target.value});}
setSafariCustomerSafariDaysInput = (text) =>{this.setState({SafariCustomerSafariDays:text.target.value});}
setCustomerAccommodationRangeInput = (text) =>{this.setState({CustomerAccommodationRange:text.target.value});}
setSafariCustomerArrivalDateInput = (text) =>{this.setState({SafariCustomerArrivalDate:text.target.value});}
setSafariCustomerOtherNotesInput = (text) =>{this.setState({SafariCustomerOtherNotes:text.target.value});}

setPackageReservationTitleInput = (text) =>{this.setState({PackageReservationTitle:text.target.value});}
setPackageCustomerNameInput = (text) =>{this.setState({PackageCustomerName:text.target.value});}
setPackageCustomerEmailInput = (text) =>{this.setState({PackageCustomerEmail:text.target.value});}
setPackageCustomerPhoneInput = (text) =>{this.setState({PackageCustomerPhone:text.target.value});}
setPackageCustomerCountryInput = (text) =>{this.setState({PackageCustomerCountry:text.target.value});}
setPackageCustomerNumberOfPeopleInput = (text) =>{this.setState({PackageCustomerNumberOfPeople:text.target.value});}
setPackageCustomerAccommodationRangeInput = (text) =>{this.setState({PackageCustomerAccommodationRange:text.target.value});}
setPackageCustomerArrivalDateInput = (text) =>{this.setState({PackageCustomerArrivalDate:text.target.value});}
setPackageCustomerOtherNotesInput = (text) =>{this.setState({PackageCustomerOtherNotes:text.target.value});}



showSafariReservationScreen=()=>{
    this.setState({ShowSafariReservationScreen:true})
    this.setState({ShowPackageReservationScreen:false})
    this.showSafariReservationButton()
}
showPackageReservationScreen=()=>{
    this.setState({ShowPackageReservationScreen:true})
    this.setState({ShowSafariReservationScreen:false})
    this.showPackageReservationButton()
}

showSafariReservationButton = ()=>{
    this.setState({ShowSafariReservationButton:true})
    this.setState({ShowPackageReservationButton:false})
    this.setState({ShowPostButton:true}); 

}
showPackageReservationButton = ()=>{
    this.setState({ShowSafariReservationButton:false})
    this.setState({ShowPackageReservationButton:true})
    this.setState({ShowPostButton:true}); 
}

postSafariReservation =  async () => {
    let bookingDate = getTodaysDate();
    let safariBookingTitle = this.state.SafariReservationTitle;
    let safariCustomerName = this.state.SafariCustomerName;
    let safariCustomerPhone = this.state.SafariCustomerPhone;
    let safariCustomerEmail = this.state.SafariCustomerEmail;
    let safariCustomerCountry = this.state.SafariCustomerCountry;
    let safariCustomerNumberOfPeople = this.state.SafariCustomerNumberOfPeople;
    let safariCustomerSafariDays = this.state.SafariCustomerSafariDays;
    let safariCustomerArrivalDate = this.state.SafariCustomerArrivalDate;
    let safariCustomerOtherNotes = this.state.SafariCustomerOtherNotes;

    // console.log("bookingDate + " + bookingDate)
    // console.log("safariBookingTitle + " + safariBookingTitle)
    // console.log("safariCustomerName + " + safariCustomerName)
    // console.log("safariCustomerPhone + " + safariCustomerPhone)
    // console.log("safariCustomerEmail + " + safariCustomerEmail)
    // console.log("safariCustomerCountry + " + safariCustomerCountry)

    // console.log("safariCustomeNumberOfPeople + " + safariCustomerNumberOfPeople)
    // console.log("safariCustomerSafariDays + " + safariCustomerSafariDays)
    // console.log("safariCustomerArrivalDate + " + safariCustomerArrivalDate)
    // console.log("safariCustomerOtherNotes + " + safariCustomerOtherNotes)

    if (safariCustomerName.length === 0 || safariCustomerEmail.length === 0 || safariCustomerNumberOfPeople.length === 0 
        || safariCustomerArrivalDate.length === 0 || safariCustomerSafariDays.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('bookingTitle',safariBookingTitle);
        formData.append('bookingDate', bookingDate);
        formData.append('customerName',safariCustomerName);
        formData.append('customerPhone',safariCustomerPhone);
        formData.append('customerEmail',safariCustomerEmail);
        formData.append('customerCountry',safariCustomerCountry);
        formData.append('customerNumberOfPeople',safariCustomerNumberOfPeople);
        formData.append('customerSafariDays',safariCustomerSafariDays);
        formData.append('customerArrivalDate',safariCustomerArrivalDate);
        formData.append('customerOtherNotes',safariCustomerOtherNotes);
    
        try {
                const response = await axios.post(APISafariReservationCreate, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});   
                this.setState({ShowSafariReservationButton:true})
                this.setState({ShowPackageReservationButton:true})                

            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}

postPackageReservation =  async () => {

    let bookingTitle = this.state.PackageReservationTitle;
    let bookingDate = getTodaysDate();
    let packageCustomerName = this.state.PackageCustomerName;
    let packageCustomerPhone = this.state.PackageCustomerPhone;
    let packageCustomerEmail = this.state.PackageCustomerEmail;
    let packageCustomerCountry = this.state.PackageCustomerCountry;
    let packageCustomerNumberOfPeople = this.state.PackageCustomerNumberOfPeople;
    let packageCustomerArrivalDate = this.state.PackageCustomerArrivalDate;
    let packageCustomerOtherNotes = this.state.PackageCustomerOtherNotes;


    if (packageCustomerName.length === 0 || packageCustomerEmail.length === 0 || packageCustomerNumberOfPeople.length === 0 
        || packageCustomerArrivalDate.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('bookingTitle', bookingTitle);
        formData.append('bookingDate', bookingDate);
        formData.append('customerName', packageCustomerName);
        formData.append('customerPhone', packageCustomerPhone);
        formData.append('customerEmail', packageCustomerEmail);
        formData.append('customerCountry', packageCustomerCountry);
        formData.append('customerNumberOfPeople', packageCustomerNumberOfPeople);
        formData.append('customerArrivalDate', packageCustomerArrivalDate);
        formData.append('customerOtherNotes', packageCustomerOtherNotes);
    
        try {
                const response = await axios.post(APIPackageReservationCreate, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});   
                this.setState({ShowSafariReservationButton:true})
                this.setState({ShowPackageReservationButton:true})                 

            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
render() {
    const { 

        SafariNamesList,
        PackageNamesList,
        // Safari
        SafariCustomerEmail, 
        SafariCustomerName,
        SafariCustomerPhone,
        SafariCustomerCountry,
        SafariCustomerNumberOfPeople,
        SafariCustomerSafariDays,
        SafariCustomerArrivalDate,
        SafariCustomerOtherNotes,

        // Package
        PackageCustomerEmail, 
        PackageCustomerName,
        PackageCustomerPhone,
        PackageCustomerCountry,
        PackageCustomerNumberOfPeople,
        PackageCustomerArrivalDate,
        PackageCustomerOtherNotes,

    } =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            {this.state.ShowAxiosErrorAlert ?(<>
                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                    <p>{this.state.AllInputsRequiredError}</p>
                    <p>{this.state.AxiosErrorMessage}</p>
                    <p>{this.state.AxiosErrorResponse}</p>
                </div>
            </>):(<></>)}
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                    <div className="col-lg-4">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <center>
                                        <img src={TourIcon} alt="File Name" className=" reservation-img-left"/>
                                    </center>
                                    <br></br>
                                    <h6 className='black-color-text'> <b>Which Type Of Reservation ??</b></h6>
                                    <div>
                                        {this.state.ShowSafariReservationButton?(<>
                                            <button type="button" onClick={()=>this.showSafariReservationScreen()} className="btn btn-success rounded-pill">Safari Reservation </button>
                                        </>):(<></>)}
                                        {this.state.ShowPackageReservationButton?(<>
                                            <button type="button" onClick={()=>this.showPackageReservationScreen()} className="btn btn-success rounded-pill tour-package-book-package-btn ">Packages Reservation  </button>
                                        </>):(<></>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card custom-card-no-color">
                                {this.state.ShowSafariReservationScreen ?(<>
                                    <div className="card-body">
                                        <center><h5 className="card-title"><b>Safari Booking Details</b></h5></center>
                                        <div className='safari-booking-grid-container-columns'>
                                        <div className='my-grid-item'>
                                                <p className="primary-text">Safari Name </p>
                                                <select style={{width:"80%"}} className="form-control form-inputs" 
                                                onChange={text => this.setSafariCustomerReservationTitleInput (text)}>
                                                    <option > select</option>
                                                    {SafariNamesList && SafariNamesList.map((item,index)=>(
                                                        <option key={index} value={item} > {item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Name | Required * </p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={SafariCustomerName} placeholder='Name Required'
                                                        onChange={text => this.setSafariCustomerNameInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Email  | Required *</p>
                                                <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                        value={SafariCustomerEmail} placeholder='Email  Required'
                                                        onChange={text => this.setSafariCustomerEmailInput(text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Phone </p>
                                                <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                        value={SafariCustomerPhone} placeholder='Phone'
                                                        onChange={text => this.setSafariCustomerPhoneInput(text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Country | Required * </p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={SafariCustomerCountry} placeholder='Country | Required'
                                                        onChange={text => this.setSafariCustomerCountryInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Number of people | Required *</p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={SafariCustomerNumberOfPeople} placeholder='Number of people Required'
                                                        onChange={text => this.setSafariCustomerNumberOfPeopleInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Safari Days | Required *</p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={SafariCustomerSafariDays} placeholder='Safari Required'
                                                        onChange={text => this.setSafariCustomerSafariDaysInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Accommodation Range | Required *</p>
                                                <select style={{width:"80%"}} className="form-control form-inputs" 
                                                onChange={text => this.setCustomerAccommodationRangeInput (text)}>
                                                    <option > select</option>
                                                    <option value="Low Class"> Low Class</option>
                                                    <option value="Mid Class"> Mid Class</option>
                                                    <option value="High Class"> High Class</option>
                                                </select>
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Arrival Date | Required *</p>
                                                <input style={{width:"80%"}} type='date' className="form-control form-inputs"
                                                        value={SafariCustomerArrivalDate}
                                                        onChange={text => this.setSafariCustomerArrivalDateInput(text)}
                                                    />
                                            </div>
                                        </div>
                                        <br></br>
                                        <p className="primary-text">Other Notes</p>
                                        <textarea style={{width:"80%"}} className="form-control form-inputs"
                                                value={SafariCustomerOtherNotes} placeholder='Additional Notes'
                                                onChange={text => this.setSafariCustomerOtherNotesInput(text)}
                                                ></textarea>
                                        <br></br>
                                        <center>
                                            {this.state.ShowPostButton?(<>
                                                <button type="button" onClick={()=>this.postSafariReservation()} className="btn btn-primary">Submit My Reservation</button>
                                            </>):(<></>)}
                                        </center>
                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                            <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.AllInputsRequiredError}
                                            </div>
                                        </>):(<></>)}
                                    </div>
                                </>):(<></>)}
                                {this.state.ShowPackageReservationScreen ?(<>
                                    <div className="card-body">
                                        <center><h5 className="card-title"><b>Package Booking Details</b></h5></center>
                                        <div >
                                            <p className="primary-text">Package Name </p>
                                            <select style={{width:"80%"}} className="form-control form-inputs" 
                                                onChange={text => this.setPackageReservationTitleInput (text)}>
                                                <option > select</option>
                                                {PackageNamesList && PackageNamesList.map((item,index)=>(
                                                    <option key={index} value={item} > {item}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='safari-booking-grid-container-columns'>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Package Name </p>
                                                <select style={{width:"80%"}} className="form-control form-inputs" 
                                                    onChange={text => this.setPackageReservationTitleInput (text)}>
                                                    <option > select</option>
                                                    {PackageNamesList && PackageNamesList.map((item,index)=>(
                                                        <option key={index} value={item} > {item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Name | Required * </p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={PackageCustomerName} placeholder='Name Required'
                                                        onChange={text => this.setPackageCustomerNameInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Email  | Required *</p>
                                                <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                        value={PackageCustomerEmail} placeholder='Email  Required'
                                                        onChange={text => this.setPackageCustomerEmailInput(text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Phone </p>
                                                <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                        value={PackageCustomerPhone} placeholder='Phone'
                                                        onChange={text => this.setPackageCustomerPhoneInput(text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Nationality | Required * </p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={PackageCustomerCountry} placeholder='Nationality Required'
                                                        onChange={text => this.setPackageCustomerCountryInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Number of people | Required *</p>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                        value={PackageCustomerNumberOfPeople} placeholder='Number of people Required'
                                                        onChange={text => this.setPackageCustomerNumberOfPeopleInput (text)}
                                                    />
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Accommodation Range | Required *</p>
                                                <select style={{width:"80%"}} className="form-control form-inputs" 
                                                onChange={text => this.setPackageCustomerAccommodationRangeInput (text)}>
                                                    <option > select</option>
                                                    <option value="Low Class"> Low Class</option>
                                                    <option value="Mid Class"> Mid Class</option>
                                                    <option value="High Class"> High Class</option>
                                                </select>
                                            </div>
                                            <div className='my-grid-item'>
                                                <p className="primary-text">Arrival Date | Required *</p>
                                                <input style={{width:"80%"}} type='date' className="form-control form-inputs"
                                                        value={PackageCustomerArrivalDate}
                                                        onChange={text => this.setPackageCustomerArrivalDateInput(text)}
                                                    />
                                            </div>
                                        </div>
                                        <br></br>
                                        <p className="primary-text">Other Notes</p>
                                        <textarea style={{width:"80%"}} className="form-control form-inputs"
                                                value={PackageCustomerOtherNotes} placeholder='Additional Notes'
                                                onChange={text => this.setPackageCustomerOtherNotesInput(text)}
                                                ></textarea>
                                        <br></br>
                                        <center>
                                        {this.state.ShowPostButton?(<>
                                            <button type="button" onClick={()=>this.postPackageReservation ()} className="btn btn-primary">Submit My Reservation</button>
                                        </>):(<></>)}
                                        </center>

                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                            <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.AllInputsRequiredError}
                                            </div>
                                        </>):(<></>)}
                                    </div>
                                </>):(<></>)}
                            </div>
                        </div>
                    </div>
                </section>

                
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Reservation);