
export const clearLocalStorageSafariBookingDetails = () =>{
    localStorage.removeItem('SafariBookingDetails');
}

export const clearLocalStoragePackageBookingDetails = () =>{
    localStorage.removeItem('PackageBookingDetails');
}
export const formatNumberWithComma = (numb) => {
    let str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
}

export const convertToUpperCase = (Name) =>{
    return Name.toUpperCase();
}

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const getTodaysDate = ()=>{
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${month}/${day}/${year}`;
    // console.log(formattedDate); // Outputs: MM/DD/YYYY
    return formattedDate;
};

export const displayNestedData = (Data) => {
    return(JSON.parse(Data).map((item, itemIndex) => (
        <div key={itemIndex}>
            <p className="dashboard-card-description"> {item.data}</p>
        </div>
    )))
} 
export const displayNestedPackageData = (Data) => {
    console.log("::::"+Data)
    return(JSON.parse(Data).map((item, itemIndex) => (
        <div key={itemIndex}>
            <p > {item.data}</p>
        </div>
    )))
} 