import React,{} from 'react';
import {Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import whatsAppIcon from "../../Utilities/imgs/whatsApp.png";

function VerticalHeader () {
return (
        <div  >
            <Navbar className='nav-header-container' data-bs-theme="light" >
                <Container >
                    <Navbar.Brand href="#home">Logo</Navbar.Brand>
                    <Nav className='me-auto d-flex flex-column align-items-center'>
                        <br></br>
                        <Nav.Link as={Link} to="/">
                            <span className='nav-links-text'>Home</span>
                        </Nav.Link>
                        
                        <Nav.Link as={Link} to="/aboutUs">
                            <span className='nav-links-text'>About</span>
                        </Nav.Link>
                        <Nav.Link as={Link} to="/packages" >
                            <span className='nav-links-text'>Packages</span>
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contactUs" >
                            <span className='nav-links-text'>Contact Us</span>
                        </Nav.Link>
                    <button type="button" className="btn btn-light rounded-pill call-now-btn "><i className="bi bi-whatsapp me-1"></i> Call : 07000000 </button>
                    <Link to="/reservation" >
                        <button type="button" className="btn btn-primary rounded-pill book-now-btn">Make Reservation </button>
                    </Link>
                    </Nav>
                </Container>
            </Navbar>
            
            <div>
                <a  href="https://wa.me/2" className="whatsapp_float">
                    <img src={whatsAppIcon} alt="WhatsApp" />
                </a><br/><br/>
            </div>
        </div>
);
}

export default VerticalHeader;