

import React,{Component} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';


class AboutUs extends Component {
constructor(props){
    
    super(props);
    this.state = {

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

    }    
}

componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('SafariBookingDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let title = jsonData.title;
        this.setState({BookingTitle:title});
    }
}
render() {
    const { } =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            {this.state.ShowAxiosErrorAlert ?(<>
                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                    <p>{this.state.AllInputsRequiredError}</p>
                    <p>{this.state.AxiosErrorMessage}</p>
                    <p>{this.state.AxiosErrorResponse}</p>
                </div>
            </>):(<></>)}
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                    <div class="col-lg-4">
                            <div class="card custom-card-no-color">
                                <div class="card-body">
                                    <center><h5 className="card-title"><b>Isoke Pearl Safari Ltd</b></h5></center>
                                <br></br>
                                {/* <h6 className='black-color-text'> <b>Isoke Pearl Safari Ltd</b></h6> */}
                                {/* <h5 className="card-title">BookingTitle</h5> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <center><h5 className="card-title"><b>About Us Info</b></h5></center>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(AboutUs);