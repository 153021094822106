

import React,{Component} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import {APIPackageFileName, AUTH_TOKEN } from '../../Utilities/APIS';
// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class PackageDetails extends Component {
constructor(props){
    
    super(props);
    this.state = {
        FileName:'',
        PackageTitleName:'',
        ActivityHighlights:[],
        WhyBookThisTrip:[],
        DetailedItinerary:[]
    }    
}


componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('PackageDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let fileName = jsonData.fileName;
        let packageTitleName = jsonData.packageTitleName;
        let activityHighlights = jsonData.activityHighlights;
        let whyBookThisTrip = jsonData.whyBookThisTrip;
        let detailedItinerary = jsonData.activityHighlights;

        this.setState({FileName:fileName});
        this.setState({PackageTitleName:packageTitleName});
        this.setState({WhyBookThisTrip:JSON.parse(whyBookThisTrip)});
        this.setState({ActivityHighlights:JSON.parse(activityHighlights)});
        this.setState({DetailedItinerary:JSON.parse(detailedItinerary)});
    }
}
render() {
    const { DetailedItinerary,WhyBookThisTrip,ActivityHighlights} = this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>

                <section className="section">
                    <div className="row">
                        <center>
                            <div className="col-lg-6">
                                {/* <div className="card custom-card-no-color">
                                    <div className="card-body">
                                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={APIPackageFileName+fileName} alt="Profile" className=" d-block w-100 safaris-details-card-img"></img>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    <br></br>
                                    </div>
                                </div> */}
                                <img src={APIPackageFileName+this.state.FileName} alt="Profile" className=" d-block w-100 safaris-details-card-img"></img>

                            </div>
                        </center>

                        <div className="col-lg-12">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <h5 className="card-title"><b>{this.state.PackageTitleName}</b></h5>
                                    <h6 className="card-title">Detailed Itinerary !</h6>
                                    {DetailedItinerary && DetailedItinerary.map((item,index)=>(
                                        <div key={index}>
                                            <p > {item.data}</p>
                                        </div>
                                    ))} 
                                    <h6 className="card-title">Why Book This Trip ?</h6>
                                    {WhyBookThisTrip && WhyBookThisTrip.map((item,index)=>(
                                        <div key={index}>
                                            <p > {item.data}</p>
                                        </div>
                                    ))} 
                                    <h6 className="card-title">Activity High Lights !!</h6>
                                    {ActivityHighlights && ActivityHighlights.map((item,index)=>(
                                        <div key={index}>
                                            <p > {item.data}</p>
                                        </div>
                                    ))} 
                                    <center>
                                        <Link to={"/packageBooking"} >
                                            <button type="button" className="btn btn-success rounded-pill">Book This Package</button>
                                        </Link>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(PackageDetails);