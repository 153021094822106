

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';


import { APISafariFileName, APISafariList, APISliderFileName, APISliderList, AUTH_TOKEN } from '../../Utilities/APIS';
import { displayNestedData } from '../../Utilities/Functions';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Safaris extends Component {
constructor(props){
    
    super(props);
    this.state = {
        index:0,
        SafarisData:[],
        SliderData:[],
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APISafariList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SafarisData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })

    axios.get(APISliderList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SliderData:jsonResults})

        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
setBookingData = (fileName,title,shortDescription,longDescription)=>{

    let Details = { fileName, title, shortDescription, longDescription };
    localStorage.setItem('SafariBookingDetails',JSON.stringify(Details));
    this.props.navigate("/safariBooking");
}

handleSelect = (selectedIndex) => {
    this.setState({index:selectedIndex});
}


goToSafarisDetails=(fileName,title,shortDescription,longDescription)=>{
    let Details = { fileName, title};
    let SafariDetails = {fileName,title,shortDescription,longDescription};
    localStorage.setItem('SafariDetails',JSON.stringify(SafariDetails));
    localStorage.setItem('SafariBookingDetails',JSON.stringify(Details));
    this.props.navigate("/safariDetail");
}

displaySafariData = () => {
    return (
        this.state.SafarisData && this.state.SafarisData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const safariItem = this.state.SafarisData[index + i];
                                return safariItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APISafariFileName + safariItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="..." 
                                                />
                                                <h5 className="card-title dashboard-card-title">
                                                    {safariItem.safariTitleName}
                                                </h5>
                                                {displayNestedData(safariItem.shortDescription)}
                                                <br />
                                                <div>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => this.goToSafarisDetails(
                                                            safariItem.fileName, 
                                                            safariItem.safariTitleName, 
                                                            safariItem.shortDescription, 
                                                            safariItem.longDescription
                                                        )} 
                                                        className="btn btn-success rounded-pill"
                                                    >
                                                        Read More ..
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => this.setBookingData(
                                                            safariItem.fileName, 
                                                            safariItem.safariTitleName, 
                                                            safariItem.shortDescription, 
                                                            safariItem.longDescription
                                                        )} 
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn"
                                                    >
                                                        Book Safari
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}


render() {
const { SliderData} =this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <div className="rows">
                    <div className="col-lg-12">
                        <Carousel activeIndex={this.state.index} onSelect={()=>this.handleSelect()}>
                            {SliderData && SliderData.map((item, index) => (
                                <Carousel.Item key={index} interval={3000}>
                                    <img 
                                        src={`${APISliderFileName}${item.fileName}`} 
                                        className="d-block w-100 carousel-img" 
                                        alt={`Slide ${index + 1}`} 
                                    />
                                    <Carousel.Caption>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
                <br></br>
                {this.displaySafariData()}                
                <Footer/>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(Safaris);