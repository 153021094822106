

import React,{Component} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';


import {APISafariFileName, AUTH_TOKEN } from '../../Utilities/APIS';
// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class SafariDetail extends Component {
constructor(props){
    
    super(props);
    this.state = {

        FileName:'',
        Title:'',
        ShortDescription:[],
        LongDescription:[],
    }    
}

componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('SafariDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let fileName = jsonData.fileName;
        let title = jsonData.title;
        let shortDescription = jsonData.shortDescription;
        let longDescription = jsonData.longDescription;

        this.setState({FileName:fileName});
        this.setState({Title:title});
        this.setState({ShortDescription:JSON.parse(shortDescription)});
        this.setState({LongDescription:JSON.parse(longDescription)});
    }
}
render() {
const { FileName,Title,ShortDescription,LongDescription} =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-6">

                        <div className="card custom-card">
                            <div className="card-body">
                            {/* <h5 className="card-title">Example Card</h5> */}
                            <img src={APISafariFileName+FileName} alt="Profile" className=" d-block w-100 safaris-details-card-img"></img>
                            <br></br>
                            <center>
                                <Link to={"/safariBooking"} >
                                    <button type="button" className="btn btn-success rounded-pill">Book This Safari</button>
                                </Link>
                            </center>
                            </div>
                        </div>

                        </div>

                        <div className="col-lg-6">

                        <div className="card custom-card">
                            <div className="card-body">
                            <h5 className="card-title primary-yellow-color-text"><b>{Title}</b></h5>
                            {ShortDescription && ShortDescription.map((item,index)=>(
                                <div key={index}>
                                    <p className="dashboard-card-description"> {item.data}</p>
                                </div>
                            )) }
                            {LongDescription && LongDescription.map((item,index)=>(
                                <div key={index}>
                                    <p className="dashboard-card-description"> {item.data}</p>
                                </div>
                            )) }
                            </div>
                        </div>

                        </div>
                    </div>
                </section>

                
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(SafariDetail);