

import React,{Component} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIPackageFileName, APIPackageList, AUTH_TOKEN } from '../../Utilities/APIS';
import { displayNestedPackageData } from '../../Utilities/Functions';
const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class TourPackages extends Component {
constructor(props){
    
    super(props);
    this.state = {
        PackageData:[]
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIPackageList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({PackageData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
setBookingData = (fileName, packageTitleName)=>{

    let Details = { fileName, packageTitleName};
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageBooking");
}


goToPackageDetails=(fileName,packageTitleName,activityHighlights,whyBookThisTrip,detailedItinerary)=>{
    let Details = { fileName, packageTitleName};
    let PackageDetails = { fileName,packageTitleName,activityHighlights,whyBookThisTrip,detailedItinerary};
    localStorage.setItem('PackageDetails',JSON.stringify(PackageDetails));
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageDetail");
}


displayPackageData = () => {
    return (
        this.state.PackageData && this.state.PackageData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const packageItem = this.state.PackageData[index + i];
                                return packageItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card">
                                            <div className="card-body custom-card">
                                                <center>
                                                    <img 
                                                        src={APIPackageFileName + packageItem.fileName} 
                                                        className="d-block w-100 tour-package-image" 
                                                        alt="..." 
                                                    />
                                                </center>
                                                <div className="card tour-package-custom-card">
                                                    <div className="card-body tour-package-custom-card">
                                                        <h5 className="card-title">{packageItem.packageTitleName}</h5>
                                                        {/* {displayNestedPackageData (packageItem.detailedItinerary)} */}
                                                        <h6 className="card-title">Why Book This Trip ?</h6>
                                                        {displayNestedPackageData (packageItem.whyBookThisTrip)}
                                                        <h6 className="card-title">Activity High Lights !!</h6>
                                                        {displayNestedPackageData (packageItem.activityHighlights)}

                                                    </div>
                                                    <div className="card-footer tour-package-custom-card">
                                                        <div >
                                                            <button type="button" 
                                                                onClick={()=>this.goToPackageDetails(
                                                                    packageItem.fileName,packageItem.packageTitleName,packageItem.activityHighlights,
                                                                    packageItem.whyBookThisTrip,packageItem.detailedItinerary)} 
                                                                    className="btn btn-success rounded-pill">Read More ..</button>
                                                            <button type="button" 
                                                                onClick={()=>this.setBookingData(packageItem.fileName,packageItem.packageTitleName)} 
                                                                    className="btn btn-primary rounded-pill tour-package-book-package-btn ">Book Package </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}

render() {
// const { CompletedData,PublishedData,WorkingProgressData,ResearchersData} =this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                {this.displayPackageData()}
                <Footer/>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


export default withNavigation(TourPackages);