import React from 'react';
import VerticalHeader from './VerticalHeader';
import HorizontalHeader from './HorizontalHeader';

function Header() {

    return (
        <>  
            <div id="horizontal-menu">
                <HorizontalHeader/>
            </div>
            <div id="vertical-menu">
                <VerticalHeader/>
            </div>
        </>
    );
}

export default Header;