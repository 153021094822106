

import React,{Component} from 'react';
import {useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APISafariBooking, APISafariFileName, AUTH_TOKEN } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import {clearLocalStorageSafariBookingDetails, getTodaysDate } from '../../Utilities/Functions';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class SafariBooking extends Component {
constructor(props){
    
    super(props);
    this.state = {

        // Booking
        BookingTitle:'',
        CustomerName:'',
        CustomerEmail:'',
        CustomerPhone:'',
        CustomerCountry:'',
        CustomerNumberOfPeople:'',
        CustomerSafariDays:'',
        CustomerAccommodationRange:'',
        CustomerArrivalDate:'',
        CustomerOtherNotes:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

        // Posting
        SuccessPostMsg:'',
        ErrorPostMsg:'',
        ShowPostButton:true,
        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

    }    
}


componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('SafariBookingDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let fileName = jsonData.fileName;
        let title = jsonData.title;

        this.setState({FileName:fileName});
        this.setState({BookingTitle:title});
    }
}

setCustomerNameInput = (text) =>{this.setState({CustomerName:text.target.value});}
setCustomerEmailInput = (text) =>{this.setState({CustomerEmail:text.target.value});}
setCustomerPhoneInput = (text) =>{this.setState({CustomerPhone:text.target.value});}
setCustomerCountryInput = (text) =>{this.setState({CustomerCountry:text.target.value});}
setCustomerNumberOfPeopleInput = (text) =>{this.setState({CustomerNumberOfPeople:text.target.value});}
setCustomerSafariDaysInput = (text) =>{this.setState({CustomerSafariDays:text.target.value});}
setCustomerAccommodationRangeInput = (text) =>{this.setState({CustomerAccommodationRange:text.target.value});}
setCustomerArrivalDateInput = (text) =>{this.setState({CustomerArrivalDate:text.target.value});}
setCustomerOtherNotesInput = (text) =>{this.setState({CustomerOtherNotes:text.target.value});}


postSafariBooking =  async () => {

    let bookingTitle = this.state.BookingTitle;
    let bookingDate = getTodaysDate();
    let customerName = this.state.CustomerName;
    let customerPhone = this.state.CustomerPhone;
    let customerEmail = this.state.CustomerEmail;
    let customerCountry = this.state.CustomerCountry;
    let customerNumberOfPeople = this.state.CustomerNumberOfPeople;
    let customerSafariDays = this.state.CustomerSafariDays;
    let customerArrivalDate = this.state.CustomerArrivalDate;
    let customerOtherNotes = this.state.CustomerOtherNotes;



    if (customerName.length === 0 || customerEmail.length === 0 || customerNumberOfPeople.length === 0 
        || customerArrivalDate.length === 0 || customerSafariDays.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('bookingDate', bookingDate);
        formData.append('bookingTitle', bookingTitle);
        formData.append('customerName', customerName);
        formData.append('customerPhone', customerPhone);
        formData.append('customerEmail', customerEmail);
        formData.append('customerCountry', customerCountry);
        formData.append('customerNumberOfPeople', customerNumberOfPeople);
        formData.append('customerSafariDays', customerSafariDays);
        formData.append('customerArrivalDate', customerArrivalDate);
        formData.append('customerOtherNotes', customerOtherNotes);
    
        try {
                const response = await axios.post(APISafariBooking, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                clearLocalStorageSafariBookingDetails();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
render() {
    const { CustomerEmail, 
        CustomerName,
        CustomerPhone,
        CustomerCountry,
        CustomerNumberOfPeople,
        CustomerSafariDays,
        CustomerArrivalDate,
        CustomerOtherNotes,
    } =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            {this.state.ShowAxiosErrorAlert ?(<>
                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                    <p>{this.state.AllInputsRequiredError}</p>
                    <p>{this.state.AxiosErrorMessage}</p>
                    <p>{this.state.AxiosErrorResponse}</p>
                </div>
            </>):(<></>)}
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                    <div class="col-lg-4">
                            <div class="card custom-card-no-color">
                                <div class="card-body">
                                <center>
                                    <img src={APISafariFileName+this.state.FileName} alt="File Name" class="rounded-circle booking-img-left"/>
                                </center>
                                <br></br>
                                <h6 className='black-color-text'> <b>Safari Name</b></h6>
                                <h5 className="card-title">{this.state.BookingTitle}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                <center><h5 className="card-title"><b>Safari Booking Details</b></h5></center>
                                <div className='safari-booking-grid-container-columns'>
                                <div className='my-grid-item'>
                                        <p className="primary-text">Safari Name </p>
                                        <input style={{width:"80%"}} className="form-control form-inputs"
                                                value={this.state.BookingTitle} readOnly
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Name | Required * </p>
                                        <input style={{width:"80%"}} className="form-control form-inputs"
                                                value={CustomerName} placeholder='Name Required'
                                                onChange={text => this.setCustomerNameInput (text)}
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Email  | Required *</p>
                                        <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                value={CustomerEmail} placeholder='Email  Required'
                                                onChange={text => this.setCustomerEmailInput(text)}
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Phone </p>
                                        <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                value={CustomerPhone} placeholder='Phone'
                                                onChange={text => this.setCustomerPhoneInput(text)}
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Country | Required * </p>
                                        <input style={{width:"80%"}} className="form-control form-inputs"
                                                value={CustomerCountry} placeholder='Country | Required'
                                                onChange={text => this.setCustomerCountryInput (text)}
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Number of people | Required *</p>
                                        <input style={{width:"80%"}} className="form-control form-inputs"
                                                value={CustomerNumberOfPeople} placeholder='Number of people Required'
                                                onChange={text => this.setCustomerNumberOfPeopleInput (text)}
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Safari Days | Required *</p>
                                        <input style={{width:"80%"}} className="form-control form-inputs"
                                                value={CustomerSafariDays} placeholder='Safari Required'
                                                onChange={text => this.setCustomerSafariDaysInput (text)}
                                            />
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Accommodation Range | Required *</p>
                                        <select style={{width:"80%"}} className="form-control form-inputs" 
                                        onChange={text => this.setCustomerAccommodationRangeInput (text)}>
                                            <option > select</option>
                                            <option value="Low Class"> Low Class</option>
                                            <option value="Mid Class"> Mid Class</option>
                                            <option value="High Class"> High Class</option>
                                        </select>
                                    </div>
                                    <div className='my-grid-item'>
                                        <p className="primary-text">Arrival Date | Required *</p>
                                        <input style={{width:"80%"}} type='date' className="form-control form-inputs"
                                                value={CustomerArrivalDate}
                                                onChange={text => this.setCustomerArrivalDateInput(text)}
                                            />
                                    </div>
                                </div>
                                <br></br>
                                <p className="primary-text">Other Notes</p>
                                <textarea style={{width:"80%"}} className="form-control form-inputs"
                                        value={CustomerOtherNotes} placeholder='Additional Notes'
                                        onChange={text => this.setCustomerOtherNotesInput(text)}
                                        ></textarea>
                                <br></br>
                                <center>
                                    {this.state.ShowPostButton?(<>
                                        <button type="button" onClick={()=>this.postSafariBooking()} className="btn btn-primary">Submit My Safari</button>
                                    </>):(<></>)}
                                </center>

                                {this.state.ShowAlertSuccessPostMsg ?(<>
                                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.SuccessPostMsg}
                                    </div>
                                </>):(<></>)}
                                {this.state.ShowAlertErrorPostMsg ?(<>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.ErrorPostMsg}
                                    </div>
                                </>):(<></>)}
                                {this.state.ShowAlertAllInputsRequired ?(<>
                                    <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.AllInputsRequiredError}
                                    </div>
                                </>):(<></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(SafariBooking);