

import React,{Component} from 'react';
import {useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIContactUsCreate, AUTH_TOKEN } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { clearLocalStoragePackageBookingDetails, getTodaysDate } from '../../Utilities/Functions';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class ContactUs extends Component {
constructor(props){
    
    super(props);
    this.state = {

        // Contacting
        CustomerName:'',
        CustomerEmail:'',
        CustomerSubject:'',
        CustomerOtherNotes:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

    }    
}

componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('PackageBookingDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let title = jsonData.title;
        this.setState({BookingTitle:title});
    }
}

setCustomerNameInput = (text) =>{this.setState({CustomerName:text.target.value});}
setCustomerEmailInput = (text) =>{this.setState({CustomerEmail:text.target.value});}
setCustomerSubjectInput = (text) =>{this.setState({CustomerSubject:text.target.value});}
setCustomerOtherNotesInput = (text) =>{this.setState({CustomerOtherNotes:text.target.value});}


postContactUsMsg =  async () => {

    let customerName = this.state.CustomerName;
    let customerEmail = this.state.CustomerEmail;
    let customerSubject = this.state.CustomerSubject;
    let customerOtherNotes = this.state.CustomerOtherNotes;
    let contactDate = getTodaysDate();



    if (customerName.length === 0 || customerEmail.length === 0 || customerSubject.length === 0)
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('contactDate', contactDate);
        formData.append('customerName', customerName);
        formData.append('customerEmail', customerEmail);
        formData.append('customerSubject', customerSubject);
        formData.append('customerOtherNotes', customerOtherNotes);
    
        try {
                const response = await axios.post(APIContactUsCreate, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                clearLocalStoragePackageBookingDetails()
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
render() {
    const { CustomerEmail, 
        CustomerName,
        CustomerSubject,
        CustomerOtherNotes,
    } =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            {this.state.ShowAxiosErrorAlert ?(<>
                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                    <p>{this.state.AllInputsRequiredError}</p>
                    <p>{this.state.AxiosErrorMessage}</p>
                    <p>{this.state.AxiosErrorResponse}</p>
                </div>
            </>):(<></>)}
            <div className='inner-body-container'>
                <section className="section contact">
                    <div className="row gy-4">
                        <div className="col-xl-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="info-box card custom-card-no-color">
                                    <i className="bi bi-geo-alt"></i>
                                    <h3>Address</h3>
                                    <p>A108 Adam Street,<br/>Kampala , NY 535022</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="info-box card custom-card-no-color">
                                    <i className="bi bi-telephone"></i>
                                    <h3>Call Us</h3>
                                    <p>+1 5589 55488 55<br/>+1 6678 254445 41</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="info-box card custom-card-no-color">
                                    <i className="bi bi-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>info@example.com<br/>contact@example.com</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="info-box card custom-card-no-color">
                                    <i className="bi bi-clock"></i>
                                    <h3>Open Hours</h3>
                                    <p>Monday - Friday<br/>9:00AM - 05:00PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="card p-4 custom-card-no-color">
                                <div className="row gy-4">
                                <div className="col-md-6">
                                    <p className="primary-text">Name | Required * </p>
                                    <input style={{width:"80%"}} className="form-control form-inputs"
                                            value={CustomerName} placeholder='Name Required'
                                            onChange={text => this.setCustomerNameInput (text)}
                                        />
                                </div>
                                <div className="col-md-6 ">
                                    <p className=" primary-text">Email  | Required *</p>
                                    <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                            value={CustomerEmail} placeholder='Email  Required'
                                            onChange={text => this.setCustomerEmailInput(text)}
                                        />
                                </div>
                                <div className="col-md-12">
                                    <p className=" primary-text">Subject | Required * </p>
                                    <input style={{width:"80%"}} className="form-control form-inputs"
                                            value={CustomerSubject} placeholder='Subject Required'
                                            onChange={text => this.setCustomerSubjectInput (text)}
                                        />
                                </div>
                                <div className="col-md-12">
                                    <p className=" primary-text">Other Information</p>
                                    <textarea style={{width:"80%"}} className="form-control form-inputs"
                                            value={CustomerOtherNotes} placeholder='Additional Notes'
                                            onChange={text => this.setCustomerOtherNotesInput(text)}
                                            ></textarea>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button type="button"className="btn btn-primary">Send My Message </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(ContactUs);