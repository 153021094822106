import { APP_AUTH_TOKEN, APP_URL } from "./Env";

const BaseUrl = APP_URL; // Set App url
export const AUTH_TOKEN = APP_AUTH_TOKEN;


// Slider 
export const APISliderList = BaseUrl+"/api/isoke/slider/list/all";
export const APISliderFileName = BaseUrl+"/api/isoke/slider/image/file/";


// Safaris 
export const APISafariBooking = BaseUrl+"/api/isoke/safari/booking/create";
export const APISafariList = BaseUrl+"/api/isoke/safari/list/all";
export const APISafariFileName = BaseUrl+"/api/isoke/safari/image/file/";
export const APISafariReservationCreate = BaseUrl+"/api/isoke/safari/reservation/create";
export const APISafariNamesList = BaseUrl+"/api/isoke/safari/list/names";

// Package 
export const APIPackageBooking = BaseUrl+"/api/isoke/package/booking/create";
export const APIPackageList = BaseUrl+"/api/isoke/package/list/all";
export const APIPackageFileName = BaseUrl+"/api/isoke/package/image/file/";
export const APIPackageReservationCreate = BaseUrl+"/api/isoke/package/reservation/create";
export const APIPackageNamesList = BaseUrl+"/api/isoke/package/list/names";

// Contact Us
export const APIContactUsCreate = BaseUrl+"/api/isoke/contact/us/create";