

import React,{Component} from 'react';
import axios from 'axios';
import { Link, useNavigate} from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';

class Footer extends Component {
constructor(props){
    
    super(props);
    this.state = {
        
    }    
}

componentDidMount(){
}


render() {
const { CompletedData,PublishedData,WorkingProgressData,ResearchersData} =this.state;
return (
            <div className="row footer-container">
                
                <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card footer-cards shadow h-100 py-2">
                        <span  >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">isoke pearl safaris</div>
                                        <div className="h5 mb-0 font-weight-bold text-light text-gray-800">Logo</div>
                                    </div>
                                    {/* <div className="col-auto">
                                        <i className="bi bi-brush-fill text-warning"></i>
                                    </div>  */}
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card footer-cards shadow h-100 py-2">
                            <span >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">
                                        Location</div>
                                        <div className="h6 mb-0 font-weight-bold text-light text-gray-800">10</div>
                                    </div>
                                    {/* <div className="col-auto">
                                        <i className="bi bi-brush-fill text-success"></i>
                                    </div>  */}
                                </div>
                            </div>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4 ">
                        <div className="card footer-cards shadow h-100 py-2">
                        <span  >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">
                                        Quick Links</div>
                                        <div className="h6 mb-0 font-weight-bold text-light">2</div>
                                    </div>
                                    {/* <div className="col-auto">
                                        <i className="bi bi-brush-fill text-primary"></i>
                                    </div>  */}
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card footer-cards shadow h-100 py-2">
                        <span   >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">Contact Us</div>
                                        <div className="h6 mb-0 mr-3 font-weight-bold  text-light  text-gray-800">2</div>
                                    </div>
                                    {/* <div className="col-auto">
                                        <i className="bi bi-eye-fill text-info"></i>
                                    </div>  */}
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>
            </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


export default withNavigation(Footer);